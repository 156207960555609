<template>
  <div>
  </div>
</template>

<script>
import { oauth2Token, userinfo, getAuthentication } from '@/service/api';

export default {
  name: 'tyLogin',
  data() {
    return {
    };
  },
  mounted() {
    // let message = '';
    // let key = '';
    // let authorization = '';
    // const data = Object.assign({ code: this.$route.query.code }, this.$store.state.settings.tyApplicationInfo);
    const redirectUri = process.env.VUE_APP_REDIRECT_URI;
    // const data = Object.assign({ code: this.$route.query.code, redirectUri: window.location.origin + '/callback' });
    const data = Object.assign({ code: this.$route.query.code, redirectUri: redirectUri });
    console.log('callback页面61111111', data, this.$route);
    getAuthentication(data).then((res1) => {
      if (res1.code === 200) {
        this.$store.dispatch('app/setToken', res1.data.access_token);
        this.$store.dispatch('app/setRefreshToken', res1.data.refresh_token);
        this.$utils.asyncGetUserInfo(this.$route.query);
      }
    });
    // getAuthentication().then((res1) => {
    //   if (res1.code === 200) {
    //     message = res1.data.slice(0, -8);
    //     key = res1.data.slice(-8);
    //     let decrypted = this.$utils.decryptDes(message, key);
    //     // 测试环境
    //     // authorization = 'Basic ' + decrypted;
    //     authorization = 'Basic OGFkYTM3YzAyNjFmNGNlOWJjYWI6MDBiYzcxM2MwZWYyNDIxNWIwZGQ=';
    //     localStorage.setItem('authorization', authorization);
    //     oauth2Token(data).then((res2) => {
    //       if (res2.code === 200) {
    //         this.$store.dispatch('app/setToken', res2.data.access_token);
    //         this.$store.dispatch('app/setRefreshToken', res2.data.refresh_token);
    //         userinfo().then((res3) => {
    //           this.$store.dispatch('app/setUserInfo', res3.data);
    //           const setPath = this.$utils.getCookie('setPath');
    //           if (setPath) {
    //             // this.$utils.removeCookie('setPath')
    //             this.$router.push(setPath);
    //           } else {
    //             this.$router.push('/');
    //           }
    //         })
    //       }
    //     })
    //   }
    // })
  },
  methods: {

  }
};
</script>
